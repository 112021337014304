<template>
  <div class='suppliers-cooperation'>
    <div class="container">
      <h2 class="suppliers-cooperation__title">{{$t('coopTitle')}}</h2>
      <div class="suppliers-cooperation__items">
        <div class="suppliers-cooperation__item">
          <div class="suppliers-cooperation__icon">
            <img src="@/assets/img/icons/suppliers/1.svg" alt="icon">
          </div>
          <div class="suppliers-cooperation__descr">{{$t('coopText1')}}</div>
        </div>
        <div class="suppliers-cooperation__item">
          <div class="suppliers-cooperation__icon">
            <img src="@/assets/img/icons/suppliers/2.svg" alt="icon">
          </div>
          <div class="suppliers-cooperation__descr">{{$t('coopText2')}}</div>
        </div>
        <div class="suppliers-cooperation__item">
          <div class="suppliers-cooperation__icon">
            <img src="@/assets/img/icons/suppliers/3.svg" alt="icon">
          </div>
          <div class="suppliers-cooperation__descr">{{$t('coopText3')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SuppliersCooperation'
  }
</script>